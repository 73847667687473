import { Cluster } from './OracleAccounts'

export class NetworkConnection {
  public cluster: Cluster
  public networkName: string
  public networkUrl: string

  constructor(cluster: Cluster, networkName: string, networkUrl: string) {
    this.cluster = cluster
    this.networkName = networkName
    this.networkUrl = networkUrl
  }

  displayName(): string {
    let cluster = 'Testnet'
    if (this.cluster === Cluster.Devnet) {
      cluster = 'Devnet'
    }
    if (this.cluster === Cluster.Mainnet) {
      cluster = 'Mainnet'
    }
    return `${cluster} | ${this.networkName}`
  }
}

const TritonSecretMainnet = process.env.NEXT_PUBLIC_TRITON_MAINNET_SECRET
const TritonSecretDevnet = process.env.NEXT_PUBLIC_TRITON_DEVNET_SECRET
export const NetworkConnections = [
  // new NetworkConnection(
  //   Cluster.Mainnet,
  //   'Syndica',
  //   'https://solana-api.syndica.io/access-token/hMvCigOlVwsbbxdhmk0NI9Ik1e2Z7K5iXmhtn8hZ8K63UlqChQzhzKzl9OtGOBQ3/'
  // ),
  new NetworkConnection(
    Cluster.Mainnet,
    'Free Helius',
    'https://mainnet.helius-rpc.com/?api-key=92007c8f-24fc-4e44-86b8-2bb1bcaad107'
  ),
  new NetworkConnection(
    Cluster.Mainnet,
    'Quicknode Free Tier',
    'https://summer-greatest-layer.solana-mainnet.quiknode.pro/d5a1f46937344f0c5bd4ceb6d74c19bd4aff36c5/'
  ),
  // new NetworkConnection(
  //   Cluster.Mainnet,
  //   'Triton',
  //   TritonSecretMainnet || 'https://hedgeso-main-726c.mainnet.rpcpool.com/'
  // ),
  // new NetworkConnection(
  //   Cluster.Mainnet,
  //   'Quicknode',
  //   'https://misty-frosty-pond.solana-mainnet.quiknode.pro/3dae9f501117a5bc43a15e9aeb052b03732bdc52/'
  // ),
  // new NetworkConnection(Cluster.Mainnet, 'Solana', 'https://api.mainnet-beta.solana.com'),
  new NetworkConnection(
    Cluster.Devnet,
    'Triton',
    TritonSecretDevnet || 'https://hedgeso-develope-f69e.devnet.rpcpool.com/'
  ),
  new NetworkConnection(
    Cluster.Devnet,
    'Quicknode',
    'https://summer-proud-night.solana-devnet.quiknode.pro/17c270209a8c3b5de1957ee7909e4e092ae313e4/'
  ),
  new NetworkConnection(Cluster.Devnet, 'Solana', 'https://api.devnet.solana.com'),
  // new NetworkConnection(Cluster.Devnet, 'LocalHost', 'http://localhost:8899'),
]
