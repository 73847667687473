import VaultAccount from '../accounts/VaultAccount'
import VaultType from '../accounts/VaultType'
import _ from 'underscore'
import { Program } from '@project-serum/anchor'
import { Vault } from 'hedge-web3'
import { PublicKey } from '@solana/web3.js'

const LoadUserVaults = async (
  program: Program<Vault>,
  userWalletPublicKey: PublicKey
): Promise<VaultAccount[]> => {

  if (!userWalletPublicKey) {
    return []
  }
  const userVaults = await program.account.vault.all([
    { memcmp: { bytes: userWalletPublicKey.toString(), offset: 8 } },
  ])
  const vaultTypes = await program.account.vaultType.all()

  const vaults = []

  userVaults.forEach(vaultData=>{
    const vaultTypeData = _.find(vaultTypes, typeData=>{
      return typeData.account.vaultTypeName === vaultData.account.vaultTypeName
    })
    if (vaultTypeData) {
      const vaultType = new VaultType(vaultTypeData.account, vaultData.account.vaultType)
      const vault = new VaultAccount(vaultData.account, vaultData.publicKey, vaultType)
      vaults.push(vault)
    } else {
      console.error("Found a vault with no type!")
    }
  })
  return vaults.sort((a,b)=>{
    return b.timeLastInteraction - a.timeLastInteraction
  })
}

export default LoadUserVaults
