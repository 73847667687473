import { toast } from "react-toastify";


export function handleConnectionError(error) {
  console.log("Error", error)
  toast.error("Error connecting to RPC Node.", {
    autoClose: 10000,
    toastId: 'connection-error'
  });
}
