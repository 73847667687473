export class Explorer {
  public name: string
  public baseUrl: string

  constructor(name: string, baseUrl: string) {
      this.name = name
      this.baseUrl = baseUrl
  }  
}

export const Explorers = [
  new Explorer('SolanaFM', 'https://solana.fm'),
  new Explorer('SolScan', 'https://solscan.io' ),
  new Explorer('Explorer', 'https://explorer.solana.com')
]