import { BN } from '@project-serum/anchor'
import { WRAPPED_SOL_MINT } from '@project-serum/serum/lib/token-instructions'
import { LAMPORTS_PER_SOL, PublicKey } from '@solana/web3.js'
import Decimal from 'decimal.js'
import VaultTypeForClient from '../on-chain/accounts/VaultTypeForClient'
import { Collateral } from '../on-chain/utils/OracleAccounts'

const vaults: VaultTypeForClient[] = []

// Used on Mainnet and Devnet
vaults.push(
  new VaultTypeForClient({
    name: 'SOL-110-0-------',
    collateral: Collateral.SOL,
    collateralIcon: 'sol.png',
    loanInitFee: new BN(0.005 * LAMPORTS_PER_SOL),
    minCollateralRatio: new BN(1.1 * LAMPORTS_PER_SOL),
    interestPerSecond: new BN(0),
    minDebt: new BN(200 * LAMPORTS_PER_SOL),
    maxDebt: new BN(1000000000).mul(new BN(LAMPORTS_PER_SOL)),
    redeemable: true,
    collateralMint: WRAPPED_SOL_MINT,
    quickDescription:
      'Borrow USH with 0% ongoing interest. Pay an upfront 0.5% loan initiation fee.',
    title: 'SOL - 110%',
    displayInUI: true,
    lamportsPerCollateralUnit: new Decimal(LAMPORTS_PER_SOL),
    emergencyModeThreshold: new BN(150),
    enableLeverage: false,
    maxLeverage: 4,
    bannerDescription: 'Best for long term leverage'
  })
)
// Live on Mainnet
vaults.push(
  new VaultTypeForClient({
    name: 'MSOL-110-0------',
    collateral: Collateral.MSOL,
    collateralIcon: 'msol.png',
    loanInitFee: new BN(0.005 * LAMPORTS_PER_SOL),
    minCollateralRatio: new BN(1.1 * LAMPORTS_PER_SOL),
    interestPerSecond: new BN(0),
    minDebt: new BN(200 * LAMPORTS_PER_SOL),
    maxDebt: new BN(100000000).mul(new BN(LAMPORTS_PER_SOL)),
    redeemable: true,
    collateralMint: new PublicKey('mSoLzYCxHdYgdzU16g5QSh3i5K3z3KZK7ytfqcJm7So'),
    quickDescription:
      'Use mSOL to borrow USH with 0% ongoing interest. Pay an upfront 0.5% loan initiation fee.',
    title: 'mSOL - 110%',
    displayInUI: true,
    lamportsPerCollateralUnit: new Decimal(LAMPORTS_PER_SOL),
    whiteListOnly: false,
    emergencyModeThreshold: new BN(150),
    enableLeverage: false,
    maxLeverage: 4,
    bannerDescription: 'Best for long term leverage'
  })
)

// Live on Devnet
vaults.push(
  new VaultTypeForClient({
    name: 'SOL-150-0-------',
    collateral: Collateral.SOL,
    collateralIcon: 'sol.png',
    loanInitFee: new BN(0.004 * LAMPORTS_PER_SOL),
    minCollateralRatio: new BN(1.5 * LAMPORTS_PER_SOL),
    interestPerSecond: new BN(0),
    minDebt: new BN(100 * LAMPORTS_PER_SOL),
    maxDebt: new BN(100000000).mul(new BN(LAMPORTS_PER_SOL)),
    redeemable: true,
    collateralMint: WRAPPED_SOL_MINT,
    quickDescription:
      'Borrow USH with 0% ongoing interest. Pay an upfront 0.4% loan initiation fee.',
    title: 'SOL - 150%',
    displayInUI: true,
    lamportsPerCollateralUnit: new Decimal(LAMPORTS_PER_SOL),
    whiteListOnly: false,
    emergencyModeThreshold: new BN(150),
    enableLeverage: false,
    maxLeverage: 4
  })
)

// Live on Devnet
vaults.push(
  new VaultTypeForClient({
    name: 'SOL-130-I-03----',
    collateral: Collateral.SOL,
    collateralIcon: 'sol.png',
    loanInitFee: new BN(0 * LAMPORTS_PER_SOL),
    minCollateralRatio: new BN(1.3 * LAMPORTS_PER_SOL),
    interestPerSecond: new BN(VaultTypeForClient.getInterestRatePerSecond(0.03)),
    minDebt: new BN(100 * LAMPORTS_PER_SOL),
    maxDebt: new BN(100000000).mul(new BN(LAMPORTS_PER_SOL)),
    redeemable: false,
    collateralMint: WRAPPED_SOL_MINT,
    quickDescription:
      'Best for quick loans. Pay no upfront loan initiation fee. Just a simple 3% ongoing interest rate.',
    title: 'SOL - 3%',
    displayInUI: true,
    lamportsPerCollateralUnit: new Decimal(LAMPORTS_PER_SOL),
    whiteListOnly: false,
    emergencyModeThreshold: new BN(150),
    enableLeverage: false,
    maxLeverage: 4
  })
)

// Live on Mainnet
vaults.push(
  new VaultTypeForClient({
    name: 'CUSDC-105-025-I-',
    collateral: Collateral.CUSDC,
    collateralIcon: 'cusdc.png',
    loanInitFee: new BN(0.0025 * LAMPORTS_PER_SOL),
    minCollateralRatio: new BN(1.05 * LAMPORTS_PER_SOL),
    interestPerSecond: new BN(VaultTypeForClient.getInterestRatePerSecond(0.0125)),
    minDebt: new BN(50 * LAMPORTS_PER_SOL),
    maxDebt: new BN(10000000).mul(new BN(LAMPORTS_PER_SOL)),
    redeemable: false,
    collateralMint: new PublicKey('993dVFL2uXWYeoXuEBFXR4BijeXdTv4s6BzsCjJZuwqk'),
    quickDescription:
      'Leverage Solend cUSDC yield by minting USH against it.',
    title: 'cUSDC - 105%',
    displayInUI: true,
    lamportsPerCollateralUnit: new Decimal(1000000),
    whiteListOnly: false,
    emergencyModeThreshold: new BN(100),
    enableLeverage: false,
    maxLeverage: 10,
    liqCollateralRatioBuffer: new BN(0.001 * LAMPORTS_PER_SOL),
    featuredInUI: true,
    vaultCategory: 'cTokens',
    bannerDescription: 'Degenbox Strategy'
  })
)

// Live on Mainnet
// https://solscan.io/token/9n4nbM75f5Ui33ZbPYXn59EwSgE8CGsHtAeTH5YFeJ9E
vaults.push(
  new VaultTypeForClient({
    name: 'WBTC-110-0------',
    collateral: Collateral.WBTC,
    collateralIcon: 'btc.png',
    loanInitFee: new BN(0.005 * LAMPORTS_PER_SOL),
    minCollateralRatio: new BN(1.1 * LAMPORTS_PER_SOL),
    interestPerSecond: new BN(0),
    minDebt: new BN(200 * LAMPORTS_PER_SOL),
    maxDebt: new BN(1000000).mul(new BN(LAMPORTS_PER_SOL)),
    redeemable: true,
    collateralMint: new PublicKey('9n4nbM75f5Ui33ZbPYXn59EwSgE8CGsHtAeTH5YFeJ9E'),
    quickDescription:
      'Use wBTC to borrow USH with 0% ongoing interest. Pay an upfront 0.5% loan initiation fee.',
    title: 'wBTC - 110%',
    displayInUI: true,
    lamportsPerCollateralUnit: new Decimal(1000000),
    whiteListOnly: false,
    emergencyModeThreshold: new BN(150),
    enableLeverage: false,
    maxLeverage: 4,
    bannerDescription: 'Best for long term leverage'
  })
)

// Live on Mainnet
// https://solscan.io/token/2FPyTwcZLUg1MDrwsyoP4D6s1tM7hAkHYRjkNb5w6Pxk
vaults.push(
  new VaultTypeForClient({
    name: 'SOETH-110-0-----',
    collateral: Collateral.SOETH,
    collateralIcon: 'eth.png',
    loanInitFee: new BN(0.005 * LAMPORTS_PER_SOL),
    minCollateralRatio: new BN(1.1 * LAMPORTS_PER_SOL),
    interestPerSecond: new BN(0),
    minDebt: new BN(200 * LAMPORTS_PER_SOL),
    maxDebt: new BN(1000000).mul(new BN(LAMPORTS_PER_SOL)),
    redeemable: true,
    collateralMint: new PublicKey('2FPyTwcZLUg1MDrwsyoP4D6s1tM7hAkHYRjkNb5w6Pxk'),
    quickDescription:
      'Use soETH to borrow USH with 0% ongoing interest. Pay an upfront 0.5% loan initiation fee.',
    title: 'soETH - 110%',
    displayInUI: true,
    lamportsPerCollateralUnit: new Decimal(1000000),
    whiteListOnly: false,
    emergencyModeThreshold: new BN(150),
    enableLeverage: false,
    maxLeverage: 4,
    bannerDescription: 'Best for long term leverage'
  })
)

// Live on Mainnet
vaults.push(
  new VaultTypeForClient({
    name: 'CUSDT-105-001-I-',
    collateral: Collateral.CUSDT,
    collateralIcon: 'cusdt.png',
    //                  == 0.1%
    loanInitFee: new BN(0.001 * LAMPORTS_PER_SOL),
    minCollateralRatio: new BN(1.05 * LAMPORTS_PER_SOL),
    //                                                                    == 1.5%
    interestPerSecond: new BN(VaultTypeForClient.getInterestRatePerSecond(0.015)),
    minDebt: new BN(50 * LAMPORTS_PER_SOL),
    maxDebt: new BN(2500000).mul(new BN(LAMPORTS_PER_SOL)),
    redeemable: false,
    collateralMint: new PublicKey('BTsbZDV7aCMRJ3VNy9ygV4Q2UeEo9GpR8D6VvmMZzNr8'),
    quickDescription:
      'Leverage Solend cUSDT yield by minting USH against it.',
    title: 'cUSDT - 105%',
    displayInUI: true,
    lamportsPerCollateralUnit: new Decimal(1000000),
    whiteListOnly: false,
    emergencyModeThreshold: new BN(100),
    enableLeverage: false,
    maxLeverage: 10,
    liqCollateralRatioBuffer: new BN(0.01 * LAMPORTS_PER_SOL),
    featuredInUI: true,
    vaultCategory: 'cTokens',
    bannerDescription: 'Degenbox Strategy'
  })
)

// --------------------------------------------
// ----------- DEVNET Vaults Below ------------
// --------------------------------------------

// Live on devnet
vaults.push(
  new VaultTypeForClient({
    name: 'FOO-150-0-------',
    collateral: Collateral.RAY,
    collateralIcon: 'ray.png',
    loanInitFee: new BN(0.004 * LAMPORTS_PER_SOL),
    minCollateralRatio: new BN(1.5 * LAMPORTS_PER_SOL),
    interestPerSecond: new BN(0),
    minDebt: new BN(100 * LAMPORTS_PER_SOL),
    maxDebt: new BN(100000000).mul(new BN(LAMPORTS_PER_SOL)),
    redeemable: true,
    // Fake mint
    collateralMint: new PublicKey('ErWNyJyH8EGQqh8pzfhDB3NvJ8Nirj9DEgfbEDva3Eh9'),
    quickDescription:
      'Borrow USH with 0% ongoing interest rate. Pay an upfront 0.4% loan initiation fee.',
    title: 'RAY - 150%',
    displayInUI: true,
    lamportsPerCollateralUnit: new Decimal(1000000),
    whiteListOnly: false,
    emergencyModeThreshold: new BN(100),
    enableLeverage: false,
    maxLeverage: 4
  })
)

// vaults.push(
//   new VaultTypeForClient({
//     name: 'MSOL-120',
//     collateral: Collateral.MSOL,
//     collateralIcon: 'msol.png',
//     loanInitFee: new BN(0.0125 * LAMPORTS_PER_SOL),
//     minCollateralRatio: new BN(1.2 * LAMPORTS_PER_SOL),
//     interestPerSecond: new BN(0),
//     minDebt: new BN(10 * LAMPORTS_PER_SOL),
//     maxDebt: new BN(100000000).mul(new BN(LAMPORTS_PER_SOL)),
//     redeemable: true,
//     collateralMint: WRAPPED_SOL_MINT,
//     quickDescription:
//       'Borrow USH with 0% ongoing interest. Pay an upfront 1.25% loan initiation fee.',
//     title: 'SOL - 0%',
//     displayInUI: false,
//     lamportsPerCollateralUnit: new Decimal(1000000)
//   })
// )

// vaults.push(
//   new VaultTypeForClient({
//     name: 'stSOL-120',
//     collateral: Collateral.STSOL,
//     collateralIcon: 'stsol.png',
//     loanInitFee: new BN(0.0125 * LAMPORTS_PER_SOL),
//     minCollateralRatio: new BN(1.2 * LAMPORTS_PER_SOL),
//     interestPerSecond: new BN(0),
//     minDebt: new BN(10 * LAMPORTS_PER_SOL),
//     maxDebt: new BN(100000000).mul(new BN(LAMPORTS_PER_SOL)),
//     redeemable: true,
//     collateralMint: WRAPPED_SOL_MINT,
//     quickDescription:
//       'Borrow USH with 0% ongoing interest. Pay an upfront 1.25% loan initiation fee.',
//     title: 'SOL - 0%',
//     displayInUI: false,
//     lamportsPerCollateralUnit: new Decimal(LAMPORTS_PER_SOL)
//   })
// )

export default vaults
