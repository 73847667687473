import { parsePriceData } from '@pythnetwork/client'
import { useConnection, useWallet } from '@solana/wallet-adapter-react'
import { AccountInfo, clusterApiUrl, Connection, Keypair, PublicKey } from '@solana/web3.js'
import { AggregatorAccount, loadSwitchboardProgram } from '@switchboard-xyz/switchboard-v2'
import mixpanel from 'mixpanel-browser'
import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { connect } from 'react-redux'
import { handleConnectionError } from '../lib/utils/handleConnectionError'
import { HedgeProgram } from '../on-chain/utils/HedgeConstants'
import { Collateral, getOracleAccount, OracleProvider } from '../on-chain/utils/OracleAccounts'
import {
  recentPriceThunk,
  refreshHdgPrice, refreshUserVaults, refreshUshPrice, refreshVaultSystemState,
  refreshVaultTypes,
  refreshWallet,
  reloadVaultInList,
  userSolBalanceThunk
} from '../reducers/walletReducer'

// Simple provider that updates the wallet balances any time the connection changes
// one time here vs in every page
const WalletBalanceProvider = ({ dispatch, networkConnection, children }) => {
  const { publicKey: userWalletPublicKey } = useWallet()
  const { connection } = useConnection()
  const Wallet = useWallet()
  const router = useRouter()

  useEffect(() => {
    dispatch(refreshWallet(connection, userWalletPublicKey))
  }, [userWalletPublicKey])

  useEffect(() => {
    if (userWalletPublicKey) {
      mixpanel.alias(userWalletPublicKey.toString())
      mixpanel.identify(userWalletPublicKey.toString())
      mixpanel.track('Wallet connected', {
        distinct_id: userWalletPublicKey.toString(),
      })
      mixpanel.people.set({ 'Recent Wallet': userWalletPublicKey.toString() })
    }
  }, [userWalletPublicKey])

  useEffect(() => {

    function handleUpdatePrice(accountInfo: AccountInfo<Buffer>, collateral: Collateral) {
      if (accountInfo) {
        let priceData = parsePriceData(accountInfo.data)
        if (priceData.price) {
          dispatch(recentPriceThunk(priceData.price, collateral))
        }
      }
    }
    try {
      console.log('Set up price listeners on network', networkConnection.displayName())
      const pythSolUsdAccount = getOracleAccount(
        networkConnection.cluster,
        OracleProvider.Pyth,
        Collateral.SOL
      )
      const pythMSolUsdAccount = getOracleAccount(
        networkConnection.cluster,
        OracleProvider.Pyth,
        Collateral.MSOL
      )
      const pythBtcUsdAccount = getOracleAccount(
        networkConnection.cluster,
        OracleProvider.Pyth,
        Collateral.WBTC
      )
      const pythEthUsdAccount = getOracleAccount(
        networkConnection.cluster,
        OracleProvider.Pyth,
        Collateral.SOETH
      )
      connection
        .getAccountInfo(pythSolUsdAccount)
        .then((accountInfo) => {
          console.log('Got SOL price account info:', accountInfo)
          handleUpdatePrice(accountInfo, Collateral.SOL)
        })
        .catch(handleConnectionError)
      connection
        .getAccountInfo(pythMSolUsdAccount)
        .then((accountInfo) => {
          handleUpdatePrice(accountInfo, Collateral.MSOL)
        })
        .catch(handleConnectionError)
      connection
        .getAccountInfo(pythBtcUsdAccount)
        .then((accountInfo) => {
          handleUpdatePrice(accountInfo, Collateral.WBTC)
        })
        .catch(handleConnectionError)
      connection
        .getAccountInfo(pythEthUsdAccount)
        .then((accountInfo) => {
          handleUpdatePrice(accountInfo, Collateral.SOETH)
        })
        .catch(handleConnectionError)
      const listenerSolId = connection.onAccountChange(pythSolUsdAccount, (accountInfo) => {
        handleUpdatePrice(accountInfo, Collateral.SOL)
      })
      const listenerMSolId = connection.onAccountChange(pythMSolUsdAccount, (accountInfo) => {
        handleUpdatePrice(accountInfo, Collateral.MSOL)
      })
      const listenerBtcId = connection.onAccountChange(pythBtcUsdAccount, (accountInfo) => {
        handleUpdatePrice(accountInfo, Collateral.WBTC)
      })
      const listenerEthId = connection.onAccountChange(pythEthUsdAccount, (accountInfo) => {
        handleUpdatePrice(accountInfo, Collateral.SOETH)
      })
      console.log('Add SOL price listener at id', listenerSolId)
      console.log('Add mSOL price listener at id', listenerMSolId)
      console.log('Add mSOL price listener at id', listenerEthId)
      return () => {
        console.log('Remove SOL price listener at id', listenerSolId)
        connection.removeAccountChangeListener(listenerSolId)
        console.log('Remove mSOL price listener at id', listenerMSolId)
        connection.removeAccountChangeListener(listenerMSolId)
        console.log('Remove BTC price listener at id', listenerBtcId)
        connection.removeAccountChangeListener(listenerBtcId)
        console.log('Remove ETH price listener at id', listenerEthId)
        connection.removeAccountChangeListener(listenerEthId)
      }
    } catch (error) {
      handleConnectionError(error)
    }
  }, [networkConnection])

  useEffect(() => {

    loadSwitchboardProgram(
      'mainnet-beta',
      // new Connection(clusterApiUrl('mainnet-beta')),
      connection,
      Keypair.generate()
    ).then((program) => {
      console.log('Loaded switchboard program', program)
      const aggregatorAccountCUSDC = new AggregatorAccount({
        program,
        publicKey: new PublicKey('7Y3nWv5B2rLiDBsNpkfXqa4cbJqszJos2sZVutF8R3FE'),
      })
      aggregatorAccountCUSDC.getLatestValue().then((value) => {
        console.log('Got cUSDC price from switchboard', value)
        dispatch(recentPriceThunk(value.toNumber(), Collateral.CUSDC))
      })
      const aggregatorAccountCUSDT = new AggregatorAccount({
        program,
        publicKey: new PublicKey('7xC7k76f2CQYRuzjyCdqmYM6kKHNpxBx89e7hw2xMf5Q'),
      })
      aggregatorAccountCUSDT.getLatestValue().then((value) => {
        console.log('Got cUSDT price from switchboard', value)
        dispatch(recentPriceThunk(value.toNumber(), Collateral.CUSDT))
      })
    })
  }, [networkConnection])

  useEffect(() => {
    const handleRouteChange = (url) => {
      mixpanel.track('Page view', {
        url: url,
      })
    }

    router.events.on('routeChangeStart', handleRouteChange)

    // If the component is unmounted, unsubscribe
    // from the event with the `off` method:
    return () => {
      router.events.off('routeChangeStart', handleRouteChange)
    }
  }, [])

  // Load all the vault types to show collateral etc
  // NOT live-loading
  useEffect(() => {
    const program = HedgeProgram(connection, Wallet)
    // Load total USH Supply
    dispatch(refreshVaultSystemState(program))
    dispatch(refreshVaultTypes(program))

    return () => {}
  }, [networkConnection])

  useEffect(() => {
    const program = HedgeProgram(connection, Wallet)
    // Load total USH Supply
    dispatch(refreshUserVaults(program, userWalletPublicKey))
  }, [networkConnection, userWalletPublicKey])

  useEffect(() => {
    dispatch(refreshHdgPrice())
    dispatch(refreshUshPrice())
  }, [networkConnection, userWalletPublicKey])

  useEffect(() => {
    let id: number
    if (userWalletPublicKey) {
      try {
        id = connection.onAccountChange(userWalletPublicKey, (accountInfo) => {
          dispatch(userSolBalanceThunk(accountInfo.lamports))
        })

        dispatch(refreshWallet(connection, userWalletPublicKey))
      } catch (error) {
        handleConnectionError(error)
      }
    }
    return () => {
      id && connection.removeAccountChangeListener(id)
    }
  }, [networkConnection, userWalletPublicKey])

  useEffect(() => {
    let ids = []
    const finalizeConnection = new Connection(connection.rpcEndpoint, 'finalized')
    const program = HedgeProgram(finalizeConnection, Wallet)
    ids.push(
      program.addEventListener('LiquidateVaultEvent', (event) => {
        console.log('LiquidateVaultEvent', event)
        dispatch(reloadVaultInList(program, new PublicKey(event.vault)))
        dispatch(refreshVaultSystemState(program))
        dispatch(refreshVaultTypes(program))
      })
    )
    ids.push(
      program.addEventListener('NewVaultEvent', (event) => {
        console.log('NewVaultEvent', event)
        // dispatch(reloadVaultInList(program, new PublicKey(event.vault)))
        dispatch(refreshVaultSystemState(program))
        dispatch(refreshVaultTypes(program))
      })
    )
    ids.push(
      program.addEventListener('DepositVaultEvent', (event) => {
        console.log('DepositVaultEvent', event)
        dispatch(reloadVaultInList(program, new PublicKey(event.vault)))
        dispatch(refreshVaultSystemState(program))
        dispatch(refreshVaultTypes(program))
      })
    )
    ids.push(
      program.addEventListener('WithdrawVaultEvent', (event) => {
        console.log('WithdrawVaultEvent', event)
        dispatch(reloadVaultInList(program, new PublicKey(event.vault)))
        dispatch(refreshVaultSystemState(program))
        dispatch(refreshVaultTypes(program))
      })
    )
    ids.push(
      program.addEventListener('LoanVaultEvent', (event) => {
        console.log('LoanVaultEvent', event)
        dispatch(reloadVaultInList(program, new PublicKey(event.vault)))
        dispatch(refreshVaultSystemState(program))
        dispatch(refreshVaultTypes(program))
      })
    )
    ids.push(
      program.addEventListener('RepayVaultEvent', (event) => {
        console.log('RepayVaultEvent', event)
        dispatch(reloadVaultInList(program, new PublicKey(event.vault)))
        dispatch(refreshVaultSystemState(program))
        dispatch(refreshVaultTypes(program))
      })
    )
    ids.push(
      program.addEventListener('RedeemVaultEvent', (event) => {
        console.log('RedeemVaultEvent', event)
        dispatch(reloadVaultInList(program, new PublicKey(event.vault)))
        dispatch(refreshVaultSystemState(program))
        dispatch(refreshVaultTypes(program))
      })
    )
    return () => {
      ids.forEach((id) => {
        program.removeEventListener(id)
      })
    }
  }, [networkConnection])

  return <>{children}</>
}

function mapStateToProps(state) {
  const { networkConnection } = state.wallet
  return {
    networkConnection,
  }
}
export default connect(mapStateToProps)(WalletBalanceProvider)
