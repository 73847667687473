import {
  getAssociatedTokenAddress
} from '@solana/spl-token'
import { Connection, PublicKey } from '@solana/web3.js'
import Immutable from 'immutable'
import { getHedgeMintPublicKey, getUshMintPublicKey, HEDGE_PROGRAM_PUBLICKEY } from '../on-chain/utils/HedgeConstants'

import { Program } from '@project-serum/anchor'
import base58 from 'bs58'
import { getVaultSystemStatePublicKey, Vault } from 'hedge-web3'
import { handleConnectionError } from '../lib/utils/handleConnectionError'
import VaultAccount, { VaultStatus } from '../on-chain/accounts/VaultAccount'
import VaultSystemState from '../on-chain/accounts/VaultSystemState'
import VaultType from '../on-chain/accounts/VaultType'
import LoadUserVaults from '../on-chain/loaders/LoadUserVaults'
import { NetworkConnection, NetworkConnections } from '../on-chain/utils/NetworkConnections'
import { Collateral } from '../on-chain/utils/OracleAccounts'
import { Explorer, Explorers } from '../on-chain/utils/Explorers'

// const URL_ROOT = process.env.HOSTED_URL || window.location.protocol + '//' + window.location.host + '/'

// Define a type for the slice state
export interface WalletReduxState {
  publicKey: PublicKey
  providerName: string
  networkConnection: NetworkConnection
  explorer: Explorer
  hdgPrice: number
  ushPrice: number
  usdSolPrice: number
  userSolBalance: number
  userUshBalance: number
  userHedgeBalance: number
  recentPrices: Immutable.Map<string, number>
  vaultSystemState: VaultSystemState
  vaultTypes: VaultType[]
  userVaults: VaultAccount[] | undefined
  allVaults: Immutable.Map<string, VaultAccount>
}

const cachedNetworkConnection = NetworkConnections.find((connection) => {
  if (typeof window !== 'undefined') {
    // Client-side-only code
    return connection.networkUrl === window.localStorage.getItem('network')
  }
  return false
})

const cachedExplorer = Explorers.find((explorer) => {
  if (typeof window !== 'undefined') {
    // Client-side-only code
    return explorer.baseUrl === window.localStorage.getItem('explorer')
  }
  return
})

const initialState: WalletReduxState = {
  publicKey: undefined,
  providerName: undefined,
  networkConnection: cachedNetworkConnection || NetworkConnections[0],
  explorer: cachedExplorer || Explorers[0],
  hdgPrice: 0,
  ushPrice: 0,
  usdSolPrice: 0,
  userSolBalance: 0,
  userUshBalance: 0,
  userHedgeBalance: 0,
  recentPrices: Immutable.Map({}),
  vaultSystemState: undefined,
  vaultTypes: [],
  userVaults: undefined,
  allVaults: Immutable.Map({}),
}

export default function bountyReducer(state = initialState, action) {
  switch (action.type) {
    // omit other reducer cases
    case 'wallet/connect': {
      // Replace the existing state entirely by returning the new value
      return {
        ...state,
        publicKey: action.payload.publicKey,
        providerName: action.payload.providerName,
      }
    }
    case 'wallet/network': {
      // Replace the existing state entirely by returning the new value
      return {
        ...state,
        networkConnection: action.payload.networkConnection,
      }
    }
    case 'wallet/explorer': {
      // Replace the existing state entirely by returning the new value
      return {
        ...state,
        explorer: action.payload.explorer,
      }
    }
    case 'wallet/usd-sol-price': {
      // Replace the existing state entirely by returning the new value
      return {
        ...state,
        usdSolPrice: action.payload.usdSolPrice,
      }
    }
    case 'wallet/user-sol-balance': {
      // Replace the existing state entirely by returning the new value
      return {
        ...state,
        userSolBalance: action.payload.userSolBalance,
      }
    }
    case 'wallet/recent-price': {
      return {
        ...state,
        recentPrices: state.recentPrices.set(action.payload.collateral, action.payload.price),
      }
    }
    case 'wallet/user-ush-balance': {
      // Replace the existing state entirely by returning the new value
      return {
        ...state,
        userUshBalance: action.payload.userUshBalance,
      }
    }
    case 'wallet/user-hedge-balance': {
      // Replace the existing state entirely by returning the new value
      return {
        ...state,
        userHedgeBalance: action.payload.userHedgeBalance,
      }
    }
    case 'wallet/vault-system-state': {
      // Replace the existing state entirely by returning the new value
      return {
        ...state,
        vaultSystemState: action.payload.vaultSystemState,
      }
    }
    case 'wallet/vault-types': {
      // Replace the existing state entirely by returning the new value
      return {
        ...state,
        vaultTypes: action.payload.vaultTypes,
      }
    }
    case 'wallet/update-user-vaults': {
      // Replace the existing state entirely by returning the new value
      return {
        ...state,
        userVaults: action.payload.userVaults,
      }
    }
    case 'wallet/update-all-vaults': {
      // Replace the existing state entirely by returning the new value
      return {
        ...state,
        allVaults: action.payload.allVaults,
      }
    }
    case 'wallet/vault-liquidated-event': {
      // Replace the existing state entirely by returning the new value
      return {
        ...state,
        allVaults: state.allVaults.remove(action.payload.vaultPublicKey),
      }
    }
    case 'wallet/vault-update-vault': {
      // Replace the existing state entirely by returning the new value
      return {
        ...state,
        allVaults: state.allVaults.set(
          action.payload.vault.publicKey.toString(),
          action.payload.vault
        ),
      }
    }
    case 'wallet/update-hdg-price': {
      // Replace the existing state entirely by returning the new value
      return {
        ...state,
        hdgPrice: action.payload.price,
      }
    }
    case 'wallet/update-ush-price': {
      // Replace the existing state entirely by returning the new value
      return {
        ...state,
        ushPrice: action.payload.price,
      }
    }
    default:
      return state
  }
}

// export function connectPhantomWallet () {
//   return async function connectPhantomWalletThunk (dispatch, getState) {
//     if ('solana' in window) {
//       const provider = window.solana
//       if (provider.isPhantom) {
//         window.solana.connect()
//       }
//     } else {
//       window.open('https://phantom.app/', '_blank')
//     }
//   }
// }

export function phantomConnectionThunk(publicKey, providerName) {
  return {
    type: 'wallet/connect',
    payload: {
      publicKey,
      providerName,
    },
  }
}

export function phantomNetworkThunk(networkConnection) {
  // Cache the network connection between site visits
  localStorage.setItem('network', networkConnection.networkUrl)
  return {
    type: 'wallet/network',
    payload: {
      networkConnection,
    },
  }
}
export function explorerThunk(explorer) {
  // Cache the network connection between site visits
  localStorage.setItem('explorer', explorer.baseUrl)
  return {
    type: 'wallet/explorer',
    payload: {
      explorer,
    },
  }
}
export function usdSolPriceThunk(usdSolPrice) {
  return {
    type: 'wallet/usd-sol-price',
    payload: {
      usdSolPrice,
    },
  }
}
export function userSolBalanceThunk(userSolBalance) {
  return {
    type: 'wallet/user-sol-balance',
    payload: {
      userSolBalance,
    },
  }
}
export function userUshBalanceThunk(userUshBalance) {
  return {
    type: 'wallet/user-ush-balance',
    payload: {
      userUshBalance,
    },
  }
}
export function userHedgeBalanceThunk(userHedgeBalance) {
  return {
    type: 'wallet/user-hedge-balance',
    payload: {
      userHedgeBalance,
    },
  }
}
export function recentPriceThunk(price: number, collateral: Collateral) {
  return {
    type: 'wallet/recent-price',
    payload: {
      collateral,
      price,
    },
  }
}
export function vaultSystemStateThunk(vaultSystemState: VaultSystemState) {
  return {
    type: 'wallet/vault-system-state',
    payload: {
      vaultSystemState,
    },
  }
}
export function vaultTypesThunk(vaultTypes: VaultType[]) {
  return {
    type: 'wallet/vault-types',
    payload: {
      vaultTypes,
    },
  }
}
export function updateUserVaultsThunk(userVaults: VaultAccount[] | undefined) {
  return {
    type: 'wallet/update-user-vaults',
    payload: {
      userVaults,
    },
  }
}
export function updateAllVaultsThunk(allVaults: Immutable.Map<string, VaultAccount>) {
  return {
    type: 'wallet/update-all-vaults',
    payload: {
      allVaults,
    },
  }
}
export function vaultLiquidatedEventThunk(vaultPublicKey: string) {
  return {
    type: 'wallet/vault-liquidated-event',
    payload: {
      vaultPublicKey,
    },
  }
}
export function updateVaultThunk(vault: VaultAccount) {
  return {
    type: 'wallet/vault-update-vault',
    payload: {
      vault,
    },
  }
}
export function updateHdgPrice(price: number) {
  return {
    type: 'wallet/update-hdg-price',
    payload: {
      price,
    },
  }
}
export function updateUshPrice(price: number) {
  return {
    type: 'wallet/update-ush-price',
    payload: {
      price,
    },
  }
}

export function refreshWallet(connection: Connection, userWalletPublicKey) {
  return async function refreshWalletThunk(dispatch, getState) {
    console.log('Refreshing wallet balance')

    // dispatch(userSolBalanceThunk(0))
    // dispatch(userUshBalanceThunk(0))

    if (!connection || !userWalletPublicKey) {
      return
    }

    // Update wallet Sol Balance
    connection
      .getBalance(userWalletPublicKey)
      .then((balance) => {
        console.log('BALANCE', balance)
        dispatch(userSolBalanceThunk(balance))
      })
      .catch(handleConnectionError)

    // Update wallet USH Balance
    getUshMintPublicKey().then((USHMintPublicKey) => {
      getAssociatedTokenAddress(USHMintPublicKey, userWalletPublicKey).then(
        (userUshAccountPublicKey) => {
          connection
            .getTokenAccountBalance(userUshAccountPublicKey)
            .then((userUshAccountInfo) => {
              dispatch(userUshBalanceThunk(userUshAccountInfo.value.amount))
            })
            .catch(() => {
              // No ush account yet for this user.
              dispatch(userUshBalanceThunk(0))
            })
        }
      )
    })

    // Update wallet HEDGE Balance
    getHedgeMintPublicKey().then((HedgeMintPublicKey) => {
      getAssociatedTokenAddress(HedgeMintPublicKey, userWalletPublicKey).then(
        (userHedgeTokenAccountPublicKey) => {
          connection
            .getTokenAccountBalance(userHedgeTokenAccountPublicKey)
            .then((userHedgeTokenAccountInfo) => {
              dispatch(userHedgeBalanceThunk(userHedgeTokenAccountInfo.value.amount))
            })
            .catch((error) => {
              // No ush account yet for this user.
              dispatch(userHedgeBalanceThunk(0))
            })
        }
      )
    })
  }
}

export function refreshUserVaults(program: Program<Vault>, userWalletPublicKey) {
  return async function refreshUserVaultsThunk(dispatch) {
    dispatch(updateUserVaultsThunk(undefined))

    if (!program || !userWalletPublicKey) {
      return
    }

    LoadUserVaults(program, userWalletPublicKey)
      .then((vaults) => {
        dispatch(updateUserVaultsThunk(vaults))
      })
      .catch((error) => {
        console.error('Error loading user vaults', error)
        dispatch(updateUserVaultsThunk([]))
      })
  }
}

export function refreshVaultSystemState(program: Program<Vault>) {
  console.log('Called refreshVaultSystemState')
  return async function refreshVaultSystemStateThunk(dispatch) {
    if (!program) {
      return
    }

    getVaultSystemStatePublicKey(HEDGE_PROGRAM_PUBLICKEY).then((vaultSystemStatePublicKey) => {
      program.account.vaultSystemState
        .fetch(vaultSystemStatePublicKey)
        .then((systemState) => {
          dispatch(
            vaultSystemStateThunk(new VaultSystemState(systemState, vaultSystemStatePublicKey))
          )
        })
        .catch(handleConnectionError)
    })
  }
}
export function refreshVaultTypes(program: Program<Vault>) {
  console.log('Called refreshVaultTypes')
  return async function refreshVaultTypesThunk(dispatch) {
    if (!program) {
      return
    }

    program.account.vaultType
      .all()
      .then((vaultTypes) => {
        const loadedVaultTypes = vaultTypes
          .map((vaultType) => {
            return new VaultType(vaultType.account, vaultType.publicKey)
          })
          .sort((a, b) => {
            return a.collateralIndex - b.collateralIndex
          })
        dispatch(vaultTypesThunk(loadedVaultTypes))
      })
      .catch(handleConnectionError)
  }
}

export function reloadAllVaults(program: Program<Vault>) {
  return async function reloadAllVaultsThunk(dispatch) {
    if (!program) {
      return
    }

    const vaultTypes = await program.account.vaultType
      .all()
      .then((vaultTypes) => {
        const loadedVaultTypes = vaultTypes.map((vaultType) => {
          return new VaultType(vaultType.account, vaultType.publicKey)
        })
        return loadedVaultTypes
      })
      .catch((error) => {
        handleConnectionError(error)
        return []
      })

    Promise.all(
      vaultTypes.map((vaultType) => {
        const filters = [
          // Filter for Vault Accounts
          {
            memcmp: program.account.vault.coder.accounts.memcmp(
              // @ts-ignore
              program.account.vault._idlAccount.name
            ),
          },
          // Filter for Vaults that are open
          {
            memcmp: {
              bytes: base58.encode(Buffer.from([VaultStatus.Open])),
              offset: 8 + 32 + 24,
            },
          },
          // Filter for Vaults with this collateral type
          {
            memcmp: {
              bytes: vaultType.publicKey.toString(),
              offset: 8 + 32 + 24 + 1,
            },
          },
        ]
        return program.provider.connection
          .getProgramAccounts(HEDGE_PROGRAM_PUBLICKEY, {
            filters: filters,
            // Slice the data only to grab the 3 u64's of size 8 bytes each
            dataSlice: {
              // See programs/hedge-vault/src/account_data/vault.rs for struct layout
              offset: 8 + 32 + 8,
              length: 16,
            },
          })
          .then((vaults) => {
            return vaults.map((vaultData) => {
              return VaultAccount.FromMiniSlice(vaultData.account.data, vaultData.pubkey, vaultType)
            })
          })
          .catch((error) => {
            handleConnectionError(error)
            return []
          })
        // .then((vaults) => {
        //   dispatch(updateVaultsOfTypeThunk(vaults, vaultType))
        // })
      })
    ).then((vaultsByType) => {
      let allVaults: Immutable.Map<string, VaultAccount> = Immutable.Map({})
      vaultsByType.forEach((vaults) => {
        vaults.forEach((vault) => {
          allVaults = allVaults.set(vault.publicKey.toString(), vault)
        })
      })
      dispatch(updateAllVaultsThunk(allVaults))
    })
  }
}

export function reloadVaultInList(program: Program<Vault>, vaultPublicKey: PublicKey) {
  return async function reloadVaultInListThunk(dispatch) {
    if (!program || !vaultPublicKey) {
      return
    }

    const vaultData = await program.account.vault.fetch(vaultPublicKey)

    // Load Collateral Type
    const vaultTypeData = await program.account.vaultType.fetch(vaultData.vaultType)

    if (!vaultTypeData) {
      throw new Error('No Vault Type Found')
    }

    console.log('Found vault type data', vaultTypeData)

    const vaultType = new VaultType(vaultTypeData, vaultData.vaultType)
    const vault = new VaultAccount(vaultData, vaultPublicKey, vaultType)
    dispatch(updateVaultThunk(vault))
  }
}

export function refreshHdgPrice() {
  return async function refreshHdgPriceThunk(dispatch) {
    fetch(
      'https://quote-api.jup.ag/v1/quote?inputMint=5PmpMzWjraf3kSsGEKtqdUsCoLhptg4yriZ17LKKdBBy&outputMint=EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v&amount=1000000000&slippage=1'
    )
      .then((response) => response.json())
      .then((data) => {
        const price = data?.data[0]?.outAmount
        if (price) {
          console.log('hdg price', price / 1000000)
          dispatch(updateHdgPrice(price / 1000000))
        }
      })
      .catch(handleConnectionError)
  }
}

export function refreshUshPrice() {
  return async function refreshUshPriceThunk(dispatch) {
    fetch('https://api.switchboard.xyz/api/result/8iMLNqCjJmXoYWxjh41cr2w8EGqN2QwLx6h21EgFQnfe')
      .then((response) => response.json())
      .then((data) => {
        const price = data?.result?.value
        if (price) {
          console.log('ush price', price)
          dispatch(updateUshPrice(price))
        }
      })
      .catch(handleConnectionError)
  }
}
