import Decimal from 'decimal.js'

export function numberWithCommas(x: number | Decimal | string, fixedDecimals = 0): string {
  let fixed
  if (typeof x === 'string') {
    if (isNaN(parseFloat(x))) {
      return ''
    }
    fixed = parseFloat(x).toFixed(fixedDecimals)
  } else {
    fixed = x.toFixed(fixedDecimals)
  }
  const beforeDecimal = parseFloat(fixed.substring(0, fixed.indexOf('.'))).toLocaleString('en-US')
  const afterDecimal = fixed.substring(fixed.indexOf('.') + 1)
  if (fixedDecimals === 0) {
    return parseFloat(fixed).toLocaleString('en-US')
  }

  return beforeDecimal + '.' + afterDecimal
}
