import { useState } from 'react'
import Link from 'next/link'

function SiteBanner() {
  const [hidden, setHidden] = useState(true)

  if (hidden) {
    return <></>
  }

  return (
    <div className="banner d-flex flex-row py-2">
      <span className="ps-1" onClick={() => setHidden(true)}>
        <i className="fa fa-times" />
      </span>
      <div className="flex-grow-1 text-center">
        <div className="">
          WARNING! next.hedge.com is for internal testing only!! Hedge has not been security audited
          and should only be used for testing purposes.
        </div>
        <div className="fw-bold text-decoration-underline">
          We may close this program at any time which will result in the loss of your SOL.
        </div>
        <div className="">
          We take no responsibility for any loss of funds or security issues.{' '}
          <Link href="/docs/disclaimer">More info here</Link>
        </div>
      </div>
    </div>
  )
}

export default SiteBanner
