import Immutable, { List, Map } from 'immutable'
import TransactionStep from '../lib/TransactionStep'

const initialState = {
  title: '',
  message: '',
  status: '',
  percent: 0,
  showing: false,
  transactionSteps: List(),
}

export default function loadingScreenReducer(state = initialState, action) {
  switch (action.type) {
    // omit other reducer cases
    case 'loadingScreen/setMessage': {
      // Replace the existing state entirely by returning the new value
      return {
        ...state,
        message: action.payload.message,
        status: action.payload.status,
        percent: action.payload.percent,
      }
    }
    case 'loadingScreen/setTitle': {
      return {
        ...state,
        title: action.payload.title,
      }
    }
    case 'loadingScreen/setShowing': {
      return {
        ...state,
        showing: action.payload.showing,
      }
    }
    case 'loadingScreen/setTransactionSteps': {
      return {
        ...state,
        transactionSteps: action.payload.transactionSteps,
      }
    }
    default:
      return state
  }
}

export function setLoadingScreenSuccess(message: string) {
  return async function setLoadingScreenSuccessThunk(dispatch, getState) {
    setTimeout(() => {
      dispatch(setLoadingScreenMessage('', 0))
    }, 2000)
    dispatch({
      type: 'loadingScreen/setMessage',
      payload: {
        message,
        status: 'success',
        percent: 100,
      },
    })
  }
}

export function setLoadingScreenTitle(title: string) {
  return {
    type: 'loadingScreen/setTitle',
    payload: {
      title,
    },
  }
}
export function setLoadingScreenShowing(showing: boolean) {
  return {
    type: 'loadingScreen/setShowing',
    payload: {
      showing,
    },
  }
}
export function setLoadingScreenTransactionSteps(transactionSteps: TransactionStep[]) {
  const steps = List(
    transactionSteps.map((step) => {
      return {
        status: step.status,
        title: step.title,
        signature: step.signature,
        error: step.error,
      }
    })
  )
  console.log("setLoadingScreenTransactionSteps", steps)
  return {
    type: 'loadingScreen/setTransactionSteps',
    payload: {
      transactionSteps: steps,
    },
  }
}
export function setLoadingScreenError(message: string) {
  return {
    type: 'loadingScreen/setMessage',
    payload: {
      message,
      status: 'error',
      percent: 100,
    },
  }
}
export function setLoadingScreenMessage(message: string, percent = 0) {
  return {
    type: 'loadingScreen/setMessage',
    payload: {
      message,
      status: '',
      percent: percent,
    },
  }
}
