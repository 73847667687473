import { LAMPORTS_PER_SOL, PublicKey } from '@solana/web3.js'
import Decimal from 'decimal.js'
import { u128ToDecimal } from '../utils/HedgeConstants'

import clientVaultTypes from '../../data/vaults'

export default class VaultSystemState {
  publicKey: PublicKey
  
  // Data loaded from on-chain
  lastRedeemFeeBytes: Decimal
  lastRedeemTimestamp: number
  totalUshSupply: Decimal
  totalVaults: Decimal
  totalVaultsClosed: Decimal
  debtRedistributionError: Decimal
  authority: PublicKey
  hedgeStakingPool: PublicKey
  collateralTypeCount: number
  status: string


  constructor(config: any, publicKey?: PublicKey) {
    this.publicKey = publicKey
    this.lastRedeemFeeBytes = u128ToDecimal(config.lastRedeemFeeBytes)
    this.lastRedeemTimestamp = config.lastRedeemTimestamp.toNumber()
    this.totalUshSupply = new Decimal(config.totalUshSupply.toString())
    this.totalVaults = new Decimal(config.totalVaults.toNumber())
    this.totalVaultsClosed = new Decimal(config.totalVaultsClosed.toNumber())
    this.debtRedistributionError = new Decimal(config.debtRedistributionError.toNumber())
    this.authority = config.authority
    this.hedgeStakingPool = config.hedgeStakingPool
    this.collateralTypeCount = config.collateralTypeCount.toNumber()
    this.status = Object.keys(config.status)[0]
  }
  /**
   * 
   * @returns true if the system is not halted
   */
  public isRunning(): boolean {
    return this.status === SystemStatus.Running
  }
}

export enum SystemStatus {
  Halted = 'halted',
  Running = 'running',
}
