import { WalletAdapterNetwork } from '@solana/wallet-adapter-base'
import { ConnectionProvider, WalletProvider } from '@solana/wallet-adapter-react'
import {
  LedgerWalletAdapter,
  PhantomWalletAdapter,
  SlopeWalletAdapter,
  SolflareWalletAdapter,
  SolletExtensionWalletAdapter,
  SolletWalletAdapter,
  TorusWalletAdapter,
  Coin98WalletAdapter,
} from '@solana/wallet-adapter-wallets'
import { SentreWalletAdapter } from '@sentre/connector'
import { WalletModalProvider } from '@solana/wallet-adapter-react-ui'
import { clusterApiUrl } from '@solana/web3.js'
import { useMemo } from 'react'
import { connect } from 'react-redux'

const WalletConnectionProvider = ({ children, networkConnection }) => {
  const network = WalletAdapterNetwork.Devnet
  // console.log('Wallet Connection Provider Loaded')
  // console.log(' ▷ On network:', networkConnection.displayName())
  // console.log(' ▷ At url:', networkConnection.networkUrl)

  // You can also provide a custom RPC endpoint
  // const endpoint = useMemo(() => clusterApiUrl(network), [network])
  // const endpoint = useMemo(() => networkUrl, [networkUrl])

  // @solana/wallet-adapter-wallets includes all the adapters but supports tree shaking --
  // Only the wallets you configure here will be compiled into your application
  const wallets = useMemo(
    () => [
      new PhantomWalletAdapter(),
      new SlopeWalletAdapter(),
      new SolflareWalletAdapter({ network: networkConnection.cluster }),
      new TorusWalletAdapter(),
      new LedgerWalletAdapter(),
      new SolletWalletAdapter({ network: networkConnection.cluster }),
      new SolletExtensionWalletAdapter({ network: networkConnection.cluster }),
      new Coin98WalletAdapter(),
      new SentreWalletAdapter({ appId: 'hedge' }),
    ],
    [networkConnection]
  )

  return (
    <ConnectionProvider
      endpoint={networkConnection.networkUrl}
      config={{ commitment: 'confirmed' }}
    >
      <WalletProvider wallets={wallets} autoConnect>
        <WalletModalProvider>{children}</WalletModalProvider>
      </WalletProvider>
    </ConnectionProvider>
  )
}

function mapStateToProps(state) {
  const { networkConnection } = state.wallet
  return {
    networkConnection,
  }
}
export default connect(mapStateToProps)(WalletConnectionProvider)
