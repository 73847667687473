import { combineReducers } from 'redux'

import walletReducer from './reducers/walletReducer'
import loadingScreenReducer from './reducers/loadingScreenReducer'

const rootReducer = combineReducers({
  // Define a top-level state field named `todos`, handled by `todosReducer`
  wallet: walletReducer,
  loadingScreen: loadingScreenReducer
})

export default rootReducer
